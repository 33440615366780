import { updateAssetsQueryDataByUpdatingAsset } from "domains/assets/api/updateAssetsQueryByUpdatingAsset";
import { updateAssetsQueryByUploadingAsset } from "domains/assets/api/updateAssetsQueryByUploadingAsset";
import { updateAssetsQueryDataByCopyingAsset } from "domains/assets/api/updateAssetsQueryDataByCopyingAsset";
import { updateAssetsQueryDataByUpdatingCollections } from "domains/collections/api/updateQueryDataByUpdatingCollections";
import { updateQueryDataByDeletingImages } from "domains/inference/api/updateQueryDataByDeletingImages";
import { updateQueryDataByUpdatingDescription } from "domains/models/api/updateQueryDataByUpdatingDescription";
import { updateQueryDataByUpdatingTags } from "domains/tags/api/updateQueryDataByUpdatingTags";
import { API_TAGS } from "infra/store/constants";
import { Endpoints } from "infra/store/interface";
import _ from "lodash";

export const assetsEndpoints: Endpoints = {
  getAssets: {
    merge(existing, incoming) {
      existing.assets = _.unionBy(existing.assets, incoming.assets, "id");

      // This fix is in case some assets don't update
      // existing.assets = _.chain(incoming.assets)
      //   .unionBy(_.cloneDeep(existing.assets), "id")
      //   .orderBy(
      //     queryArgs.sortBy ?? "createdAt",
      //     (queryArgs.sortDirection as "asc" | "desc") ?? "desc"
      //   )
      //   .value();

      if (!existing?.assets || existing.nextPaginationToken) {
        existing.nextPaginationToken = incoming.nextPaginationToken;
      }
    },
    serializeQueryArgs: ({ endpointName, queryArgs }) => {
      return (
        endpointName +
        queryArgs.teamId +
        queryArgs.inferenceId +
        queryArgs.modelId +
        queryArgs.rootAssetId +
        queryArgs.parentAssetId +
        queryArgs.type +
        queryArgs.types +
        queryArgs.collectionId +
        queryArgs.sortBy +
        queryArgs.sortDirection
      );
    },
    forceRefetch({ currentArg, previousArg }) {
      return !_.isEqual(currentArg, previousArg);
    },
    providesTags: (_result, _error, arg) => {
      return [
        {
          type: API_TAGS.asset,
          id: `assetId:${arg.rootAssetId}`,
        },
        {
          type: API_TAGS.asset,
          id: `assetId:${arg.parentAssetId}`,
        },
        {
          type: API_TAGS.asset,
          id: `collectionId:${arg.collectionId}`,
        },
        {
          type: API_TAGS.asset,
          id: `inferenceId:${arg.inferenceId}`,
        },
        {
          type: API_TAGS.asset,
        },
        {
          type: API_TAGS.asset,
          id: `type:${arg.type}`,
        },
        {
          type: API_TAGS.asset,
          id: `type:${arg.types}`,
        },
      ];
    },
  },

  getAssetsByAssetId: {
    onQueryStarted: async (arg, { dispatch, queryFulfilled, getState }) => {
      const { data } = await queryFulfilled;
      if (data) {
        updateAssetsQueryDataByUpdatingAsset(data.asset, {
          dispatch,
          getState,
        });
      }
    },
    providesTags: (_result, _error, arg) => {
      return [
        {
          type: API_TAGS.asset,
          id: `assetId:${arg.assetId}`,
        },
      ];
    },
  },

  postAsset: {
    invalidatesTags: (_result, _error, arg) => {
      if (arg.body.canvas) {
        return [
          {
            type: API_TAGS.asset,
            id: "type:canvas",
          },
        ];
      }
      return [];
    },
    onQueryStarted: async (arg, { dispatch, queryFulfilled, getState }) => {
      const { data } = await queryFulfilled;
      if (data && !arg.body.canvas) {
        updateAssetsQueryByUploadingAsset(
          data.asset,
          arg.body.collectionIds ?? [],
          {
            dispatch,
            getState,
          }
        );
      }
    },
  },

  putAssetByAssetId: {
    onQueryStarted: async (arg, { dispatch, queryFulfilled, getState }) => {
      if (arg.body.description !== undefined) {
        const undo = updateQueryDataByUpdatingDescription({
          assetId: arg.assetId,
          description: arg.body.description,
          dispatch,
          getState,
        }).undo;
        queryFulfilled.catch(() => undo());
      }

      const { data } = await queryFulfilled;
      if (data) {
        updateAssetsQueryDataByUpdatingAsset(data.asset, {
          dispatch,
          getState,
        });
      }
    },
    invalidatesTags: (_result, _error, arg) => {
      return [
        {
          type: API_TAGS.asset,
          id: `assetId:${arg.assetId}`,
        },
      ];
    },
  },

  putAssetsTagsByAssetId: {
    onQueryStarted: async (arg, { dispatch, queryFulfilled, getState }) => {
      const undo = updateQueryDataByUpdatingTags(
        arg.teamId ?? "",
        "asset",
        arg.assetId,
        arg.body.add ?? [],
        arg.body.delete ?? [],
        {
          dispatch,
          getState,
        }
      ).undo;
      queryFulfilled.catch(() => undo());
    },
  },

  deleteAsset: {
    onQueryStarted: async (arg, { dispatch, queryFulfilled, getState }) => {
      const undo = updateQueryDataByDeletingImages(arg.body.assetIds, {
        dispatch,
        getState,
      }).undo;

      queryFulfilled.catch(() => undo());
    },
  },

  copyAssetByAssetId: {
    onQueryStarted: async (arg, { dispatch, queryFulfilled, getState }) => {
      const { data } = await queryFulfilled;
      if (data) {
        updateAssetsQueryDataByCopyingAsset(data.asset, arg.assetId, {
          dispatch,
          getState,
        });
      }
    },
  },

  putAssetsByCollectionId: {
    onQueryStarted: async (arg, { dispatch, queryFulfilled, getState }) => {
      const undo = updateAssetsQueryDataByUpdatingCollections(
        arg.teamId,
        "add",
        arg.body.assetIds,
        arg.collectionId,
        {
          dispatch,
          getState,
        }
      ).undo;

      queryFulfilled.catch(() => undo());
    },
    invalidatesTags: (_result, _error, arg) => {
      return [
        {
          type: API_TAGS.asset,
          id: `collectionId:${arg.collectionId}`,
        },
      ];
    },
  },

  deleteAssetsByCollectionId: {
    onQueryStarted: async (arg, { dispatch, queryFulfilled, getState }) => {
      const undo = updateAssetsQueryDataByUpdatingCollections(
        arg.teamId,
        "remove",
        arg.body.assetIds,
        arg.collectionId,
        {
          dispatch,
          getState,
        }
      ).undo;

      queryFulfilled.catch(() => undo());
    },
  },
};
