import { PropsWithChildren, useMemo } from "react";
import BackgroundTaskIcon from "domains/background-tasks/components/ElementBackgroundTaskIcon";
import { useBackgroundTaskContext } from "domains/background-tasks/contexts/BackgroundTaskProvider";
import { BackgroundTask } from "domains/background-tasks/interfaces/BackgroundTask";
import Button, { ButtonProps, IconButton } from "domains/ui/components/Button";
import Divider from "domains/ui/components/Divider";
import Icon from "domains/ui/components/Icon";

import {
  Box,
  Center,
  CircularProgress,
  HStack,
  keyframes,
  Popover,
  PopoverBody,
  PopoverContent,
  PopoverTrigger,
  Text,
  Tooltip,
  VStack,
} from "@chakra-ui/react";

export default function BackgroundTasksDropdown() {
  const { recentTasks, isOpen, isLoading, clearTask, open, close } =
    useBackgroundTaskContext();

  const modelTrainingTasks = useMemo(
    () => recentTasks.filter((task) => task.type === "modelTraining"),
    [recentTasks]
  );
  const modelUploadTasks = useMemo(
    () => recentTasks.filter((task) => task.type === "modelUpload"),
    [recentTasks]
  );
  const generationTasks = useMemo(
    () =>
      recentTasks.filter(
        (task) => !["modelTraining", "modelUpload"].includes(task.type)
      ),
    [recentTasks]
  );

  return (
    <Popover isOpen={isOpen} onClose={close} placement="bottom-end">
      <Tooltip label="Background Tasks">
        <PopoverTrigger>
          <IconButton
            pos="relative"
            w="36px"
            h="36px"
            p={0}
            borderRadius="full"
            _hover={{
              bgColor: "backgroundTertiary.500",
            }}
            aria-label="Background Tasks"
            onClick={() => (isOpen ? close() : open())}
            variant="unstyled"
          >
            <Icon
              id="Ui/BackgroundTasks"
              animation={
                isLoading ? `${blinkAnimation} 1s linear infinite` : undefined
              }
              h="16px"
              color={isLoading ? "textPrimary" : "textSecondary"}
            />
          </IconButton>
        </PopoverTrigger>
      </Tooltip>

      <PopoverContent borderColor="border.500">
        <PopoverBody p={0} borderRadius="md" bgColor="background.500">
          <Box>
            <HStack
              w="100%"
              h="52px"
              px={4}
              borderColor="border.500"
              borderBottomWidth={1}
            >
              <Text flex={1} color="textPrimary" size="body.bold.md">
                Background Tasks
              </Text>

              <IconButton
                mr={-1}
                aria-label="Close"
                colorScheme="white"
                onClick={() => close()}
                variant="ghost"
                size="xs"
              >
                <Icon id="Ui/Cross" height="10px" />
              </IconButton>
            </HStack>

            <VStack align="stretch" py={3} spacing={3}>
              {modelTrainingTasks.length > 0 && (
                <TaskSection title="Model Training">
                  {modelTrainingTasks.map((task) => (
                    <TaskItem
                      key={task.id}
                      taskId={task.id}
                      type={task.type}
                      label={task.label}
                      status={task.status}
                      link={task.link}
                      progress={task.progress}
                      errorMessage={task.errorMessage}
                      onClear={clearTask}
                    />
                  ))}
                </TaskSection>
              )}

              {modelUploadTasks.length > 0 && (
                <TaskSection title="Model Upload">
                  {modelUploadTasks.map((task) => (
                    <TaskItem
                      key={task.id}
                      taskId={task.id}
                      type={task.type}
                      label={task.label}
                      status={task.status}
                      link={task.link}
                      progress={task.progress}
                      errorMessage={task.errorMessage}
                      onClear={clearTask}
                    />
                  ))}
                </TaskSection>
              )}

              {generationTasks.length > 0 && (
                <TaskSection title="Generations">
                  {generationTasks.map((task) => (
                    <TaskItem
                      key={task.id}
                      taskId={task.id}
                      type={task.type}
                      label={task.label}
                      status={task.status}
                      link={task.link}
                      progress={task.progress}
                      errorMessage={task.errorMessage}
                      onClear={clearTask}
                    />
                  ))}
                </TaskSection>
              )}

              {modelTrainingTasks.length === 0 &&
                modelUploadTasks.length === 0 &&
                generationTasks.length === 0 && (
                  <Text px={4} color="textSecondary" size="body.md">
                    No recent tasks
                  </Text>
                )}
            </VStack>

            <Divider />

            <Box px={4} py={2}>
              <Button
                variant="link"
                size="sm"
                rightIcon={<Icon id="Ui/Link" h="12px" />}
                internalLink="/background-tasks"
                target="_blank"
                onClick={() => close()}
              >
                View All
              </Button>
            </Box>
          </Box>
        </PopoverBody>
      </PopoverContent>
    </Popover>
  );
}

// ------------------------------------

interface TaskSectionProps extends PropsWithChildren {
  title: React.ReactNode;
}

function TaskSection({ title, children }: TaskSectionProps) {
  return (
    <VStack align="stretch" spacing={1}>
      <Text px={4} color="textPrimary" size="body.md">
        {title}
      </Text>
      <VStack align="stretch" spacing={0}>
        {children}
      </VStack>
    </VStack>
  );
}

// ------------------------------------

interface TaskItemProps {
  taskId: string;
  type: BackgroundTask["type"];
  label: string;
  status: BackgroundTask["status"];
  link?: ButtonProps["internalLink"];
  progress?: number;
  errorMessage?: string;
  onClear?: (id: string) => void;
}

function TaskItem({
  taskId,
  type,
  label,
  status,
  link,
  progress,
  errorMessage,
  onClear,
}: TaskItemProps) {
  return (
    <Tooltip
      hasArrow
      isOpen={status === "failed" ? undefined : false}
      label={errorMessage}
      placement="left"
    >
      <HStack
        h="36px"
        px={4}
        _hover={status === "failed" ? { bgColor: "whiteAlpha.50" } : {}}
        data-group
        spacing={3}
      >
        <Center w="20px">
          <BackgroundTaskIcon type={type} />
        </Center>

        <Text flex={1} color="textSecondary" isTruncated size="body.md">
          {label}
        </Text>

        {!!(onClear || link) && (
          <HStack display="none" _groupHover={{ display: "flex" }}>
            {!!link && (
              <Tooltip hasArrow label="See More" placement="top">
                <IconButton
                  mx={-1}
                  aria-label="See More"
                  colorScheme="white"
                  internalLink={link}
                  target="_blank"
                  size="xs"
                  variant="ghost"
                >
                  <Icon id="Ui/Link" h="12px" />
                </IconButton>
              </Tooltip>
            )}

            {!!onClear && (
              <Tooltip hasArrow label="Clear" placement="top">
                <IconButton
                  mx={-1}
                  aria-label="Clear"
                  colorScheme="white"
                  onClick={() => onClear(taskId)}
                  size="xs"
                  variant="ghost"
                >
                  <Icon id="Ui/Cross" h="10px" />
                </IconButton>
              </Tooltip>
            )}
          </HStack>
        )}

        <Box _groupHover={{ display: "none" }}>
          {(() => {
            if (status === "succeeded") {
              return <Icon id="Toast/Success" h="18px" color="success.600" />;
            } else if (status === "failed") {
              return <Icon id="Toast/Error" h="18px" color="danger.500" />;
            } else if (status === "loading") {
              return (
                <CircularProgress
                  color="textPrimary"
                  animation={
                    progress
                      ? `${blinkAnimation} 1s linear infinite`
                      : undefined
                  }
                  capIsRound
                  isIndeterminate={progress === undefined}
                  size="20px"
                  thickness="15px"
                  trackColor="whiteAlpha.200"
                  value={progress}
                />
              );
            }
          })()}
        </Box>
      </HStack>
    </Tooltip>
  );
}

// ------------------------------------

const blinkAnimation = keyframes`
  0% {
    opacity: 1;
  }
  50% {
    opacity: 0.4;
  }
  100% {
    opacity: 1;
  }
`;
