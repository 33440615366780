import React, {
  createContext,
  useCallback,
  useContext,
  useMemo,
  useState,
} from "react";
import Link, { LinkProps as InternalLinkProps } from "next/link";
import ModalUploadModel from "domains/models/components/ModalUploadModel";
import { useTeamContext } from "domains/teams/contexts/TeamProvider";
import Button from "domains/ui/components/Button";
import Icon from "domains/ui/components/Icon";
import { PremiumButtonWrapper } from "domains/ui/components/PremiumBadge";

import {
  Box,
  Center,
  Image,
  ImageProps,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  SimpleGrid,
  Skeleton,
  Stack,
  Text,
  VStack,
} from "@chakra-ui/react";

interface CreateNewModelModalProviderProps {
  isOpen: boolean;
  open: () => void;
  close: () => void;
  openUpload: () => void;
}

export const CreateNewModelModalContext =
  createContext<CreateNewModelModalProviderProps>({
    isOpen: false,
    open: () => {},
    close: () => {},
    openUpload: () => {},
  });

export function CreateNewModelModalProvider({
  children = <></>,
}: {
  children?: React.ReactNode;
}) {
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const [isModalUploadModelOpen, setIsModalUploadModelOpen] =
    useState<boolean>(false);
  const { selectedTeam } = useTeamContext();

  // ----------------------------------

  const open = useCallback(() => {
    setIsOpen(true);
  }, [setIsOpen]);

  const close = useCallback(() => {
    setIsOpen(false);
  }, [setIsOpen]);

  const openUpload = useCallback(() => {
    setIsOpen(false);
    setIsModalUploadModelOpen(true);
  }, [setIsModalUploadModelOpen]);

  // ----------------------------------

  const contextValue = useMemo(
    () => ({
      isOpen,
      open,
      close,
      openUpload,
    }),
    [isOpen, open, close, openUpload]
  );

  return (
    <>
      <CreateNewModelModalContext.Provider value={contextValue}>
        {children}

        <Modal isOpen={isOpen} onClose={close} variant="action">
          <ModalOverlay />
          <ModalContent w="80%" maxW="1400px" p={0} borderRadius="xl">
            <ModalCloseButton />
            <ModalHeader>
              <Text flex={1} textAlign="left" size="body.bold.lg">
                New Model
              </Text>
            </ModalHeader>

            <ModalBody w="100%" h="100%" p={6}>
              <SimpleGrid
                w="100%"
                h="100%"
                columns={[1, 1, 1, 3]}
                spacing={[3, 3, 3, 3, 6]}
              >
                <ModelCreateItem
                  icon={<Icon id="Domains/Tools/Train" h="18px" />}
                  title="Train your own model"
                  description="Upload images to train a custom LoRA model. Emulate styles, subjects, or themes. Custom models can be later merged to amplify the creative palette."
                  internalLink="/models/new"
                  imageSrc="/ui/model-create-train.jpg"
                  buttonLabel="Start Training"
                  onClick={() => close()}
                />

                <ModelCreateItem
                  icon={<Icon id="Domains/Tools/Blend" h="22px" />}
                  title="Compose with precision"
                  description="Mix & Match models, combining diverse artistic styles and subjects. Adjust the blend to craft a model that's uniquely yours - no training images required."
                  internalLink="/blend/new"
                  imageSrc="/ui/model-create-compose.jpg"
                  buttonLabel="Start Composing"
                  onClick={() => close()}
                />

                <PremiumButtonWrapper
                  size="lg"
                  shouldShowWrapper={selectedTeam.isFreePlan}
                >
                  <ModelCreateItem
                    icon={<Icon id="Ui/Upload" h="22px" />}
                    title="Upload a model"
                    description="Import a pre-trained model from your computer or a web URL (HuggingFace, CivitAI). Scenario supports safetensors, zip, gzip and .gz files."
                    imageSrc="/ui/model-create-upload.jpg"
                    buttonLabel="Upload"
                    onClick={selectedTeam.isFreePlan ? undefined : openUpload}
                  />
                </PremiumButtonWrapper>
              </SimpleGrid>
            </ModalBody>
          </ModalContent>
        </Modal>
      </CreateNewModelModalContext.Provider>

      <ModalUploadModel
        isOpen={isModalUploadModelOpen}
        onClose={() => setIsModalUploadModelOpen(false)}
      />
    </>
  );
}

export function useCreateNewModelModalContext() {
  return useContext<CreateNewModelModalProviderProps>(
    CreateNewModelModalContext
  );
}

// ------------------------------------

interface ModelCreateItemProps {
  icon: React.ReactNode;
  title: React.ReactNode;
  description: React.ReactNode;
  buttonLabel: React.ReactNode;
  internalLink?: InternalLinkProps["href"];
  imageSrc: ImageProps["src"];
  onClick?: () => void;
}

function ModelCreateItem({
  icon,
  title,
  description,
  buttonLabel,
  imageSrc,
  internalLink,
  onClick,
}: ModelCreateItemProps) {
  const isDisabled = onClick === undefined && internalLink === undefined;

  return (
    <Box
      as={internalLink ? Link : undefined}
      h="100%"
      cursor={isDisabled ? "default" : "pointer"}
      href={internalLink}
      onClick={onClick}
    >
      <Stack
        direction={["row", "row", "row", "column"]}
        overflow="hidden"
        w="100%"
        h="100%"
        borderWidth={1}
        borderRadius="xl"
        _hover={
          isDisabled
            ? {}
            : {
                opacity: 0.8,
              }
        }
        bgColor="background.500"
        spacing={0}
      >
        <VStack
          align="start"
          flex={1}
          w="100%"
          p={[4, 4, 6, 6, 8]}
          textAlign="start"
          spacing={4}
        >
          <Center
            w="48px"
            h="48px"
            color={isDisabled ? "textSecondary" : "textPrimary"}
            bg="backgroundSecondary.500"
            border="1px solid"
            borderColor="border.500"
            borderRadius="xl"
          >
            {icon}
          </Center>

          <VStack align="stretch" flex={1} spacing={2}>
            <Text
              color={isDisabled ? "textSecondary" : "textPrimary"}
              size="title.sm"
            >
              {title}
            </Text>
            <Text
              color={isDisabled ? "textTertiary" : "textSecondary"}
              size="body.md"
            >
              {description}
            </Text>
          </VStack>

          <Button
            size="sm"
            w={["auto", "auto", "auto", "full"]}
            pointerEvents="none"
            isDisabled={isDisabled}
          >
            {buttonLabel}
          </Button>
        </VStack>

        <Image
          w={["200px", "200px", "200px", "100%"]}
          maxH="260px"
          opacity={isDisabled ? 0.7 : 1}
          objectFit="contain"
          alt="illustration"
          fallback={
            <Skeleton
              w={["200px", "200px", "200px", "100%"]}
              maxH="260px"
              aspectRatio={100 / 85}
            />
          }
          src={imageSrc}
        />
      </Stack>
    </Box>
  );
}
